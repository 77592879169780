<template>
  <v-container>
    <v-card>
      <v-card-title>{{ $t('schedulerHistorieHeadline') }}</v-card-title>
      <v-card-text>{{ $t('schedulerHistorieSubHeadline') }}</v-card-text>
      <v-card-actions>
        <v-btn x-small :href="VUE_APP_URL_HOME" color="primary" outlined>{{ $t('Scanapage') }}</v-btn>
      </v-card-actions>
      <v-card-title>
        <v-text-field v-model="search" :append-icon="mdiMagnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :sort-by="['runLast']"
        :sort-desc="[true]"
        :items="schedulerTableComp"
        :search="search"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        :key="schedulerTableComp.length"
      >
        <template v-slot:item="props">
          <tr>
            <td>{{ getFormattedDate(props.item.createdDate) }}</td>
            <td>{{ props.item.startUrl }}</td>
            <td v-if="props.item.runLast === 0">-</td>
            <td v-if="props.item.runLast !== 0">{{ getFormattedDate(props.item.runLast) }}</td>
            <td>{{ props.item.crawlInterval }} day(s)</td>
            <td>{{ getFormattedDate(props.item.runNext) }}</td>
            <td>{{ props.item.numberOfPagesToCrawl }}</td>
            <td>
              <a
                v-if="props.item.crawlRequestIdLastRun && props.item.crawlRequestIdLastRun != '-'"
                :href="`${VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH}${getHostnameFromUrl(props.item.startUrl)}/?ucrid=${
                  props.item.crawlRequestIdLastRun
                }`"
                >last result</a
              >
            </td>
            <td>
              <v-icon
                small
                class="mr-2"
                @click="
                  $store.commit('dialog/setName', {
                    name: 'DialogScheduleATest',
                    data: {
                      scheduleId: props.item.keyId,
                      crawlRequestId: props.item.crawlRequestIdToCompare,
                      crawlerConfigs: props.item.crawlerConfigsPublic,
                    },
                  })
                "
              >
                {{ mdiPencil }}
              </v-icon>
              <v-icon small @click="deleteItem(props.item.keyId)"> {{ mdiDelete }} </v-icon>
              <v-icon small @click="runNow(props.item.keyId)"> {{ mdiRunFast }}</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="schedulerTableComp.length === 0">
      <v-card-text
        >{{ $t('schedulerNoPlannedScansYet') }}
        <v-btn x-small :href="VUE_APP_URL_HOME" color="primary" outlined>{{ $t('Scanapage') }}</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiMagnify, mdiPencil, mdiDelete, mdiRunFast } from '@mdi/js';
import { getScheduleList, runScheduleNow } from '../utils/apiScheduler';
import { getFormattedDate, secondToMinute } from '../utils/date';
import { getHostnameFromUrl } from '../utils/urls';

export default {
  name: 'SchedulerOverview',
  components: {},
  computed: {
    VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH: () => process.env.VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH,
    VUE_APP_URL_HOME: () => process.env.VUE_APP_URL_HOME,
    isLoading() {
      if (this.stillLoading === true) {
        return true;
      }
      return false;
    },
    schedulerTableComp() {
      return this.schedulerTable;
    },
  },
  data() {
    return {
      host: 1,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiRunFast,
      schedulerTable: [],
      search: '',
      deletionObject: {},
      showDialogDeletion: false,
      stillLoading: true,
      headers: [
        {
          text: 'Created',
          value: 'createdDate',
        },
        {
          text: 'Url',
          align: 'start',
          value: 'startUrl',
        },
        { text: this.$t('tableHeadRunLast'), value: 'runLast' },
        { text: this.$t('tableHeadcrawlInterval'), value: 'crawlInterval' },
        { text: this.$t('tableHeadrunNext'), value: 'runNext' },
        { text: this.$t('tableHeadnumberOfPagesToCrawl'), value: 'numberOfPagesToCrawl' },
        { text: '' },
        { text: this.$t('tableHeadActions'), value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.stillLoading = true;
      const schedulerList = await getScheduleList();

      if (!schedulerList.success) {
        this.$store.commit('message/setMessage', {
          color: 'error',
          message: `${schedulerList.data.message}`,
        });
        return;
      }
      this.schedulerTable = schedulerList.data;
      this.stillLoading = false;
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    secondToMinute(seconds) {
      return secondToMinute(seconds);
    },
    getHostnameFromUrl(url) {
      return getHostnameFromUrl(url);
    },
    setDialogDelete(message) {
      this.showDialogDeletion = message;
      if (message === false) {
        this.getList();
      }
    },
    deleteItem(keyId) {
      const deletionObject = {
        keyId,
        ressource: 'schedulerList',
      };
      this.$store.commit('dialog/setName', { name: 'DialogDeleteItem', data: deletionObject });
    },
    navigateToSummary(startUrl, crawlRequestId) {
      const { hostname } = new URL(startUrl);
      this.$router.push({ name: 'SummaryReporting', params: { hostnameToCrawl: hostname }, query: { ucrid: crawlRequestId } });
    },
    async runNow(schedulerId) {
      const result = await runScheduleNow(schedulerId);
      if (!result.success) {
        this.$store.commit('message/setMessage', {
          color: 'error',
          message: `${this.$t('schedulerMessageStartError')}: ${result.data.message}.`,
        });
        return;
      }
      this.$store.commit('message/setMessage', {
        color: 'success',
        message: this.$t('schedulerMessageStartSuccess'),
      });
      this.$router.push({ name: 'ReportHistoryOverviewPrivate' });
    },
  },
  metaInfo: {
    title: 'Scheduled Checks - Free Cookie Scanner - cookie-scanner.com',
    decription: 'See some websites already scanned and checked by cookie-scanner.com',
  },
};
</script>
