var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('schedulerHistorieHeadline')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('schedulerHistorieSubHeadline')))]),_c('v-card-actions',[_c('v-btn',{attrs:{"x-small":"","href":_vm.VUE_APP_URL_HOME,"color":"primary","outlined":""}},[_vm._v(_vm._s(_vm.$t('Scanapage')))])],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.mdiMagnify,"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{key:_vm.schedulerTableComp.length,attrs:{"headers":_vm.headers,"sort-by":['runLast'],"sort-desc":[true],"items":_vm.schedulerTableComp,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.getFormattedDate(props.item.createdDate)))]),_c('td',[_vm._v(_vm._s(props.item.startUrl))]),(props.item.runLast === 0)?_c('td',[_vm._v("-")]):_vm._e(),(props.item.runLast !== 0)?_c('td',[_vm._v(_vm._s(_vm.getFormattedDate(props.item.runLast)))]):_vm._e(),_c('td',[_vm._v(_vm._s(props.item.crawlInterval)+" day(s)")]),_c('td',[_vm._v(_vm._s(_vm.getFormattedDate(props.item.runNext)))]),_c('td',[_vm._v(_vm._s(props.item.numberOfPagesToCrawl))]),_c('td',[(props.item.crawlRequestIdLastRun && props.item.crawlRequestIdLastRun != '-')?_c('a',{attrs:{"href":("" + _vm.VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH + (_vm.getHostnameFromUrl(props.item.startUrl)) + "/?ucrid=" + (props.item.crawlRequestIdLastRun))}},[_vm._v("last result")]):_vm._e()]),_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$store.commit('dialog/setName', {
                  name: 'DialogScheduleATest',
                  data: {
                    scheduleId: props.item.keyId,
                    crawlRequestId: props.item.crawlRequestIdToCompare,
                    crawlerConfigs: props.item.crawlerConfigsPublic,
                  },
                })}}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(props.item.keyId)}}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.runNow(props.item.keyId)}}},[_vm._v(" "+_vm._s(_vm.mdiRunFast))])],1)])]}}])})],1),(_vm.schedulerTableComp.length === 0)?_c('v-card',[_c('v-card-text',[_vm._v(_vm._s(_vm.$t('schedulerNoPlannedScansYet'))+" "),_c('v-btn',{attrs:{"x-small":"","href":_vm.VUE_APP_URL_HOME,"color":"primary","outlined":""}},[_vm._v(_vm._s(_vm.$t('Scanapage')))])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }